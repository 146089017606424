import React, { useState } from "react";
import { useProject } from "../ProjectContext";
import { ProjectRow } from "../../components/ProjectRow";
import { Table } from "@mantine/core";
import { Button } from "@common/components";
import { Plus } from "tabler-icons-react";
import { MusicNote } from "@mui/icons-material";
import { AddProjectModal } from "./components/AddProjectModal";
import { Project } from "@server/entities";
import { updateProjectPost } from "../../../../requests/project/project";
import { notification } from "@common/utils/notification";

interface ProjectChildrenProps {}

export const ProjectChildren: React.FC<ProjectChildrenProps> = ({}) => {
  const { project, mutate } = useProject();
  const [addProjectModal, setAddProjectModal] = useState(false);

  const handleAddProject = async (newProject: Project) => {
    if (!project) return;
    if (!project.children) return;

    const { error } = await updateProjectPost(project.id, {
      children: project.children.concat(newProject),
    });

    if (error) return notification.error(error.message);

    await mutate();
  };

  return (
    <div>
      <AddProjectModal
        opened={addProjectModal}
        onClose={() => setAddProjectModal(false)}
        onAddProject={handleAddProject}
      />

      <div className="rounded overflow-hidden bg-dark-800">
        <Table className="w-full">
          <tbody>
            {project?.children?.map((child) => (
              <ProjectRow project={child} key={child.id} />
            ))}
            <tr className="align-middle bg-dark-900">
              <td colSpan={5}>
                <div className="flex items-center justify-center gap-4 py-4">
                  <Button leftIcon={<MusicNote className="w-5 h-5" />}>
                    Create new project
                  </Button>

                  <Button
                    leftIcon={<Plus className="w-5 h-5" />}
                    variant="light"
                    color="gray"
                    onClick={() => setAddProjectModal(true)}
                  >
                    Add existing project
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
